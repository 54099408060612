.home {
  &.header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    background-color: beige;
  }
  &.body{
    margin: 1rem;
  }
  &.image {
    border-radius: 1rem;
  }
}